import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ScreenService } from '@core/services/screen.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private dialogRefs: Array<MatDialogRef<any> | MatBottomSheetRef<any>> = [];

  constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet, private screen: ScreenService) {}

  openOver(component: ComponentType<unknown>, config?: MatDialogConfig<unknown>): Observable<any> {
    return this._open(component, config);
  }

  open(component: ComponentType<unknown>, config?: MatDialogConfig<unknown>): Observable<any> {
    this.close();
    return this._open(component, config);
  }

  private _open(component: ComponentType<unknown>, config?: MatDialogConfig<unknown>): Observable<any> {
    let ref: MatDialogRef<any> | MatBottomSheetRef<unknown, any>;
    if (this.screen.isMobile() && !this.dialogRefs.length) {
      ref = this.bottomSheet.open(component, { panelClass: 'app-dialog', data: config?.data });
      this.dialogRefs.push(ref);
    } else {
      ref = this.dialog.open(component, {
        width: config?.width || 'calc(100% - 32px)',
        height: config?.height,
        maxWidth: config?.maxWidth || 'min(calc(100vw - 32px), 1000px)',
        maxHeight: config?.maxHeight || 'min(80vh, 1000px)',
        hasBackdrop: config?.hasBackdrop || true,
        panelClass: config?.panelClass || 'app-dialog',
        backdropClass: config?.backdropClass || 'app-overlay-backdrop',
        autoFocus: config?.autoFocus || false,
        data: config?.data,
      });
      this.dialogRefs.push(ref);
    }
    const afterClosed = ref instanceof MatDialogRef ? ref.afterClosed() : ref.afterDismissed();
    // clean up dialog refs in case dialog has been closed by outside backdrop click
    // this code runs after close() function, so in case dialog been closed using this function, nothing happens, ref would not be found
    afterClosed.subscribe({ next: () => (this.dialogRefs = this.dialogRefs.filter(item => item !== ref)) });
    return afterClosed;
  }

  close(data?: any) {
    if (this.dialogRefs.length) {
      const ref = this.dialogRefs.pop();
      ref instanceof MatDialogRef ? ref?.close(data) : ref?.dismiss(data);
    }
  }

  closeAll() {
    while (this.dialogRefs.length) this.close();
  }
}
